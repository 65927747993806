import SubstanceAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/substance-allergies/SubstanceAllergiesTemplate';
import { SUBSTANCE_ALLERGY } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/Page16SubstanceAllergies/SubstanceAllergiesTemplate'
};

const createStory = props => () => ({
  components: { SubstanceAllergiesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style="$options.wrapperStyles">
      <substance-allergies-template v-bind="$options.storyProps" />
    </div>`
});

export const Base = createStory({
  substanceAllergies: [
    SUBSTANCE_ALLERGY.COSMETICS,
    SUBSTANCE_ALLERGY.CHROMIUM,
    SUBSTANCE_ALLERGY.GOLD
  ]
});

export const WithOtherOption = createStory({
  substanceAllergies: [
    SUBSTANCE_ALLERGY.COSMETICS,
    SUBSTANCE_ALLERGY.CHROMIUM,
    SUBSTANCE_ALLERGY.GOLD,
    'other'
  ],
  otherSubstanceAllergies: 'a, b, c, d'
});
